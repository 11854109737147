<template>
    <div>
        <div v-if="showDarkBackground" class="dark-bg" @click="onClickOutside" />
        <div class="modal card" :class="{ 'boxShadow': showDarkBackground, 'midder': midCard, 'darker': darkCard, 'current': current }">
            <div class="header" :style="{ marginBottom: noTitleSpace ? '-10px' : '10px' }">
                <h1 class="title">{{title}}</h1>
                <span class="close icofont-close" @click="onClickOutside"></span>
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
// import vClickOutside from 'v-click-outside';

export default {
    name: 'fixedModal',
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        noTitleSpace: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDarkBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        darkCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        midCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        current: {
            type: Boolean,
            default: false,
        },
    },
    // directives: {
    //     clickOutside: vClickOutside.directive,
    // },
    components: {
    },
    data () {
        return {

        };
    },
    created () {

    },
    computed: {

    },
    mounted () {
        document.addEventListener('keyup', this.handleKeyUp);
    },
    destroyed () {
        document.removeEventListener('keyup', this.handleKeyUp);
    },
    methods: {
        onClickOutside (event, el) {
            this.$emit('close');
        },
        handleKeyUp (event) {
            if (event.keyCode === 27) {
                this.$emit('close');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.modal {
    z-index: 15;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: fadeIn $modal-transition linear;
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    .header {
        display: flex;
        justify-content: space-between;
        @extend %titleFont;
        .title {
            font-weight: 600;
            margin-right: 10px;
        }
        .close {
            cursor: pointer;
            color: $primary;
            padding-top: 2px;
            font-weight: 600;
        }
    }
}
.dark-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $background-modal;
    z-index: 14;

    animation: fadeIn $modal-transition linear;
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
</style>
