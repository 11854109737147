var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headWrap",style:({ height: ((_vm.$staticStore.state.data.headerHeightPlusMargin - 10) + "px") })},[_c('div',{staticClass:"left box"},[_c('div',{staticClass:"sideNavOptions",class:{ hiddenOnDesktop: _vm.hideSidenav }},[_c('div',{staticClass:"toggle",on:{"click":_vm.toggleMenu,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleMenu.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', _vm.$store.state.ui.menuOpen ? 'chevron-left' : 'chevron-right'],"title":"Menu","aria-label":"Menu"}})],1)]),_c('div',{staticClass:"image"},[_c('logo',{staticClass:"lcpLogo",attrs:{"fontSize":28,"alt":"LCP Enact Logo"}})],1),_c('portal-target',{attrs:{"name":"headerLeft"}})],1),_c('div',{staticClass:"middle box"},[_c('portal-target',{attrs:{"name":"headerMiddle"}}),(_vm.showPeriodPicker)?_c('periodPicker',{attrs:{"initialDate":_vm.pageName === 'forecastHistory' ? _vm.historicForecastDate :
					_vm.$moment(this.$store.state.data.analysisDate.date).toDate(),"enabled":_vm.dataLoaded,"showPeriod":_vm.$store.getters.hasBoaNotLite || (_vm.pageName === 'dashboard' && _vm.$store.state.ui.dashboardHasPeriodPicker) ||
						_vm.pageName === 'mapPage' || _vm.pageName === 'europeMapPage' ||
						_vm.pageName === 'boaComparer' ||
						(_vm.pageName === 'terreDashboard' && !_vm.terreDashboardDailyMode),"disabledDateOverride":_vm.pageName === 'forecastHistory' ? _vm.historEnabled : null},on:{"currentDate":_vm.setDate}}):_vm._e(),(_vm.showHeaderDateRange)?_c('div',[_c('datePickerHolder',{attrs:{"fromDate":_vm.fromDateOverride.format('DDMMYYYY'),"toDate":_vm.toDateOverride.format('DDMMYYYY'),"dateInfo":_vm.dateInfo,"minimumView":_vm.minimumView,"disabledDate":_vm.disabledDate,"hideTimes":"","isHeader":"","onlyAllowSingleDay":_vm.onlyAllowSingleDay},on:{"dateChange":_vm.updateDate}})],1):_vm._e()],1),_c('div',{staticClass:"right box"},[(_vm.showPeriodPicker)?_c('div',{staticClass:"datepickerMobile nonDesktop",on:{"click":_vm.toggleMobileDatepicker,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleMobileDatepicker.apply(null, arguments)}}},[_c('span',{class:_vm.showMobileDatepicker ? 'icofont-close' : 'icofont-clock-time',attrs:{"aria-label":"Datepicker","title":"Datepicker"}})]):_c('div',{staticClass:"datepickerMobileHidden nonDesktop",on:{"click":_vm.toggleMobileDatepicker,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleMobileDatepicker.apply(null, arguments)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoaded && _vm.showMobileDatepicker && _vm.showPeriodPicker),expression:"dataLoaded && showMobileDatepicker && showPeriodPicker"}],staticClass:"datepickerDropdown"},[_c('periodPicker',{attrs:{"initialDate":_vm.$moment(this.$store.state.data.analysisDate.date).toDate(),"enabled":_vm.dataLoaded,"showPeriod":_vm.$store.getters.hasBoaNotLite || (_vm.pageName === 'dashboard' && _vm.$store.state.ui.dashboardHasPeriodPicker) ||
							_vm.pageName === 'mapPage' || _vm.pageName === 'europeMapPage' ||
							_vm.pageName === 'boaComparer' ||
							(_vm.pageName === 'terreDashboard' && !_vm.terreDashboardDailyMode)},on:{"currentDate":_vm.setDate}})],1),_c('rightHeader')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }