import Vue from 'vue';
import axios from 'axios';
import hello from 'hellojs';
import jwt_decode from 'jwt-decode';

import realTimeConnection from '@/realTimeConnection';
import router from '../router';

import {
    APPLICATIONID, SCOPE, REDIRECTURI, TENANTNAME, RESPONSETYPE,
    HELLOJSLOGINNAME, EDITPROFILEPOLICYNAME, HELLOJSEDITPROFILEPOLICY,
} from '../services/b2cConfig.js';

export default {
    namespaced: true,
    state: {
        authorisedLocalLogin: false,
        authorisedLcpLogin: false,
        authObject: null,
        authAccess: false,
        helloNetwork: {
            b2clogin: 'b2clogin',
            adB2CEditProfile: 'adB2CEditProfile',
        },
        loginDisplayType: {
            PopUp: 'popup',
            None: 'none',
            Page: 'page',
            // Page: 'page' //default is popup, if using page option, user gets redirected to b2c login page then to redirect html.
        },
        tokenExpireTime: null,
        lastBrowserRefresh: null,
        checkAuthInterval: null,
    },
    getters: {
        getAuthState: (state) => state.authObject,
        getAuthAccess: (state) => state.authAccess,
        getisLocalLogin: (state) => state.authorisedLocalLogin,
        getisLcpLogin: (state) => state.authorisedLcpLogin,
    },
    actions: {
        online ({ }, network) {
            const session = hello(network).getAuthResponse(network);
            const currentTime = (new Date()).getTime() / 1000;
            return session && session.access_token && session.expires > currentTime;
        },
        hasAccess ({ commit }) {
            // //console.log(email);
            const endpoint = `${process.env.VUE_APP_USERSETTINGS}/usersettingsapi/emailaccess`;
            return axios.get(endpoint).then((response) => {
                if (response.data === false) {
                    // no user found
                    return false;
                }
                commit('giveAccess');
                return true;
            }).catch(error => {
                if (error.response.status === 401) {
                    return false;
                }
                throw error;
            });
        },
        initLocal ({ dispatch, state }) {
            dispatch('initLocalSignIn').then(() => {
                dispatch('initEditProfile').then(() => {
                    hello.init({
                        b2clogin: APPLICATIONID,
                        adB2CEditProfile: APPLICATIONID,
                    }, {
                        redirect_uri: REDIRECTURI,
                        scope: `openid ${SCOPE}`,
                        response_type: RESPONSETYPE,
                        state: 'local',
                    });
                    state.helloNetwork = {
                        b2clogin: 'b2clogin',
                        adB2CEditProfile: 'adB2CEditProfile',
                    };
                    state.helloNetwork.b2clogin = 'b2clogin';
                    state.loginDisplayType = {
                        PopUp: 'popup',
                        None: 'none',
                        Page: 'page',
                        // Page: 'page' //default is popup, if using page option, user gets redirected to b2c login page then to redirect html.
                    };
                });
            });
        },
        async signIn ({ dispatch, state }) {
            await dispatch('policyLogin', { network: state.helloNetwork.b2clogin, displayType: state.loginDisplayType.Page });
        },
        refresh ({ dispatch, state }) {
            axios.post(`${process.env.VUE_APP_BASEADDRESS}/refreshtoken/internalb2ctoken`).then(async (response) => {
                // console.log('Getting new refresh token', response.data);
                axios.defaults.headers.common.Authorization = `Bearer ${response.data}`;
                realTimeConnection.setBearerToken(response.data);
                if (response.data === 'UNAUTH') {
                    router.push('/logout');
                    //  await dispatch('signOut');
                } else {
                    const decoded = jwt_decode(response.data);
                    state.tokenExpireTime = Vue.moment.unix(decoded.exp);
                }
            });
        },
        async signOut ({ dispatch, state }) {
            await dispatch('policyLogout', state.helloNetwork.b2clogin);
            // this.policyLogout(this.helloNetwork.localSignIn, POLICIES.adB2CEditProfile);
            // router.push('/');
        },
        async policyLogin ({ dispatch, state }, data) {
            const network = data.network;
            const displayType = data.displayType;

            let localDisplay = displayType;
            if (!localDisplay) {
                localDisplay = 'page';
            }

            // in case of silent renew, check if the session is still active otherwise ask the user to login again
            const authOnline = await dispatch('online', network);
            if (!authOnline && localDisplay === state.loginDisplayType.None) {
                localDisplay = state.loginDisplayType.Page;
            }

            hello(network)
                .login({
                    display: localDisplay,
                    page_uri: REDIRECTURI,
                }, this.log)
                .then((auth) => {
                    this.log(auth);
                    // this.isSignedIn = true;
                    dispatch('setAuthState', auth.authResponse);
                    axios.defaults.headers.common.Authorization = `Bearer ${auth.authResponse.access_token}`;
                    realTimeConnection.setBearerToken(auth.authResponse.access_token);
                    // createAuthRefreshInterceptor(axios, dispatch('refresh'));
                    // router.push('/userschemes');
                }, (e) => {
                    // console.log(e);
                    if ('Iframe was blocked' in e.error.message) {
                        dispatch('policyLogin', { network, displayType: state.loginDisplayType.Page });
                        return;
                    }
                    alert(`Signin error: ${e.error.message}`);
                });
        },
        async policyLogout ({ dispatch }, network) {
            const authOnline = await dispatch('online', network);
            if (authOnline) {
                dispatch('setAuthState', null);
                hello.logout(network, { force: true }).then((auth) => {
                }, (e) => {
                    alert(`Logout error: ${e.error.message}`);
                });
            }
        },
        initLocalSignIn () {
            hello.init({
                b2clogin: {
                    name: 'Azure B2C Local Login',
                    oauth: {
                        version: 2,
                        auth: 'https://lcpb2cdev.b2clogin.com/lcpb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Energy_Ext_nm_SignUpIn',
                        grant: 'https://lcpb2cdev.b2clogin.com/lcpb2cdev.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1_Energy_Ext_nm_SignUpIn',
                    },
                    refresh: true,
                    scope_delim: ' ',
                    logout () {
                        // get id_token from auth response
                        const idToken = hello(HELLOJSLOGINNAME).getAuthResponse().id_token;
                        // clearing local storage session
                        hello.utils.store(HELLOJSLOGINNAME, null);

                        // redirecting to Azure B2C logout URI
                        window.location = `https://lcpb2cdev.b2clogin.com/lcpb2cdev.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1_Energy_Ext_nm_SignUpIn&id_token_hint=${
                            idToken}&post_logout_redirect_uri=${REDIRECTURI}`;
                    },
                    // Don't even try submitting via form.
                    // This means no POST operations in <=IE9
                    form: false,
                },
            });
        },
        initEditProfile () {
            hello.init({
                adB2CEditProfile: {
                    name: 'Azure Active Directory B2C',
                    oauth: {
                        version: 2,
                        auth: `https://login.microsoftonline.com/tfp/${TENANTNAME}/${EDITPROFILEPOLICYNAME}/oauth2/v2.0/authorize`,
                        grant: `https://login.microsoftonline.com/tfp/${TENANTNAME}/${EDITPROFILEPOLICYNAME}/oauth2/v2.0/token`,
                    },
                    refresh: false,
                    scope_delim: ' ',
                    logout () {
                        // get id_token from auth response
                        // var id_token = hello(HELLOJSEDITPROFILEPOLICY).getAuthResponse().id_token; //check if needed
                        // clearing local storage session
                        hello.utils.store(HELLOJSEDITPROFILEPOLICY, null);
                    },
                },
            });
        },
        setAuthState ({ state, commit }, auth) {
            state.authObject = auth;
            if (auth) {
                state.tokenExpireTime = Vue.moment.unix(auth.expires);

                clearInterval(state.checkAuthInterval);

                // TODO add pop-up box here - for auth, force the refresh after box. for data, allow them to continue after box on but show persistent pop-up.
                state.checkAuthInterval = setInterval(() => {
                    if (state.tokenExpireTime <= Vue.moment()) {
                        commit('accessDeniedModalText', { type: 'expiredToken' }, { root: true });
                        return;
                    }
                    if (state.lastBrowserRefresh != null) {
                        const asleepFor = Vue.moment().diff(state.lastBrowserRefresh);
                        if (asleepFor > 5 * 60 * 1000) {
                            commit('accessDeniedModalText', { type: 'asleep' }, { root: true });
                        }
                    }
                    state.lastBrowserRefresh = Vue.moment();
                }, 10000);
            }
        },
    },
    mutations: {
        // setAuthState (state, auth) {
        //     state.authObject = auth;
        //     state.tokenExpireTime = Vue.moment.unix(auth.expires);

        //     clearInterval(state.checkAuthInterval);

        //     // TODO add pop-up box here - for auth, force the refresh after box. for data, allow them to continue after box on but show persistent pop-up.
        //     state.checkAuthInterval = setInterval(() => {
        //         if (state.tokenExpireTime <= Vue.moment()) {
        //             store.commit('accessDeniedModalText', 'expiredToken');
        //             console.log('token has expired, refresh of page needed');
        //             return;
        //         }
        //         if (state.lastBrowserRefresh != null) {
        //             const asleepFor = Vue.moment().diff(state.lastBrowserRefresh);
        //             if (asleepFor > 5 * 60 * 1000) {
        //                 store.commit('accessDeniedModalText', 'asleep');
        //                 console.log('your browser has been asleep for ' + asleepFor / 1000 / 60 + ' minutes, we recommend refreshing as data may be out of date');
        //                 return;
        //             }
        //         }
        //         state.lastBrowserRefresh = Vue.moment();
        //     }, 10000);
        // },
        setIsLocalLogin (state, value) {
            state.authorisedLocalLogin = value;
        },
        setIsLcpLogin (state, value) {
            state.authorisedLcpLogin = value;
        },
        giveAccess (state) {
            state.authAccess = {
                access: true,
                expires: Vue.moment().add(12, 'h'),
            };
        },
    },
};
