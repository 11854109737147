<template>
    <div id="app" class="niceScroll" :class="colourModeClass">
        <terms v-if="$store.state.ui.dataLoaded && !termsAgreed && $route.name !=='denied'" />
        <top-header v-if="firstRouteRan" @toggleFullscreen="toggleFullscreen" />
        <transition name="fade" mode="in-out">
            <loader v-if="!$store.state.ui.dataLoaded" />
        </transition>
        <accessDeniedModal v-if="$store.state.ui.accessDeniedModal.shown" />
        <transition name="fade" mode="out-in">
            <notifications v-if="$store.state.ui.notificationsOpen" />
        </transition>
        <transition name="fade" mode="out-in">
            <globalNotification v-if="showGlobalNotification" @closeModal="closeGlobalNotifications"/>
        </transition>
        <sideNav v-if="firstRouteRan && termsAgreed" />
            <!-- below may need v-if termsAgreed-->
        <fullscreen ref="fullscreen" class="appFullscreenWrapper" :class="{ hiddenOnDesktop: hideSidenav }"
            v-if="termsAgreed || !loggedIn"
            @change="toggleFullscreenChange" :background="colourMode === 'Dark' ? '#151517' : (colourMode === 'Blue' ? '#21416d' : '#dcdcdc')"
        >
            <div id="main">
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
                <mainModal v-if="$store.state.ui.slideOut.slideOutOpen">
                    <component :is="slideComp" v-bind="slideProps"></component>
                </mainModal>
            </div>
        </fullscreen>
        <vue-snotify />
    </div>
</template>

<script>

import fullscreen from 'vue-fullscreen';
import Vue from 'vue';
import cssVars from 'css-vars-ponyfill';
import loader from '@/components/layout/loader';
import sideNav from '@/components/sideNav/sideNav';
import terms from '@/components/views/terms';
import topHeader from '@/components/header/topHeader';
import mainModal from '@/components/layout/mainModal';
import notifications from '@/components/layout/notificationPopout';
import globalNotification from '@/components/layout/globalNotificationsModal';
import stripedBackground from '@/components/stripedBackground';
import accessDeniedModal from '@/components/layout/accessDeniedModal';

Vue.use(fullscreen);

export default {
    components: {
        loader,
        sideNav,
        terms,
        topHeader,
        mainModal,
        notifications,
        globalNotification,
        cssVars,
        stripedBackground,
        accessDeniedModal,
    },
    data () {
        return {

        };
    },

    created () {
        this.updateColour();

        if ('Notification' in window && Notification.permission !== 'granted') Notification.requestPermission();

        if (window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (const registration of registrations) {
                    registration.unregister();
                }
            });
        }
    },

    methods: {
        closeGlobalNotifications () {
            this.$store.commit('removeGlobalNotification');
        },
        toggleFullscreen () {
            this.$refs.fullscreen.toggle();
        },
        toggleFullscreenChange () {
            this.$store.commit('toggleFullscreen');
        },
        updateColour () {
            switch (this.colourMode) {
            case 'Dark':
                cssVars({
                    variables: {
                        '--headerColour': '#08090a',
                        '--darkGrad1': '#1c2c34',
                        '--darkGrad2': '#1f2227',
                        '--dark': '#111315',
                        '--mid': '#1f2227',
                        '--light': '#36393d',
                        '--primaryFont': 'white',
                        '--tableRow': '#2d2d32',
                        '--shadowColour': 'black',
                    },
                });
                break;
            case 'Light':
                cssVars({
                    variables: {
                        '--headerColour': 'rgb(0, 47, 95)',
                        '--darkGrad1': 'rgb(193, 205, 216)',
                        '--darkGrad2': 'rgb(193, 205, 216)',
                        '--dark': '#dcdcdc',
                        '--mid': '#ebebed',
                        '--light': '#ffffff',
                        '--primaryFont': '#333333',
                        '--tableRow': '#fefefe',
                    },
                });
                break;
            }
        },
    },
    computed: {
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
        selectedEnact () {
            return this.$store.state.ui.selectedEnactView;
        },
        componentToUse () {
            return this.$store.state.ui.slideOutObject;
        },
        colourMode () {
            return this.$store.state.user.colour;
        },
        colourModeClass () {
            let colourClass = '';
            switch (this.colourMode) {
            case 'Dark':
                colourClass = 'dark';
                break;
            case 'Light':
                colourClass = '';
                break;
            }
            if (this.$route.name === 'currentHolder') colourClass += ' theCurrent';
            if (this.$route.name === 'freqOut') colourClass += ' freqOut';
            return colourClass;
        },
        browserNotifications () {
            return this.$store.state.subscriptions.browserNotifications;
        },
        currentView () {
            return this.$route;
        },
        authenticated () {
            return this.$store.getters['auth/getAuthState'];
        },
        firstRouteRan () {
            return this.$store.state.ui.firstRouteRan;
        },
        termsAgreed () {
            return this.$store.state.user.terms.agreed;
        },
        slideComp () {
            return this.$store.state.ui.slideOut.slideOutComponent ? () => import(`./${this.$store.state.ui.slideOut.slideOutComponent}`) : '';
        },
        slideProps () {
            return this.$store.state.ui.slideOut.slideOutProps;
        },
        showGlobalNotification () {
            return this.$store.state.ui.showGlobalNotification;
        },
        hideSidenav () {
            return !this.$store.state.user.sideNavVisible;
        },
    },
    watch: {
        browserNotifications (newVal, oldVal) {
            const alert = newVal[newVal.length - 1];
            const body = `${alert.data.hoverOver}`;
            const title = `${alert.browserPushSubscriptionName}`;
            if (!document.hidden) {
                this.$snotify.success(body, title).on('click', () => {
                    this.$router.push(alert.data.clickEventUrl);
                });
            } else {
                if ('Notification' in window && Notification?.permission) {
                    const n = new Notification(title, {
                        body,
                        icon: '@/assets/imgs/notification.png',
                    });
                    n.onclick = (event) => window.open(`${process.env.VUE_APP_BASE_URL || 'http://localhost:8080'}${alert.data.clickEventUrl}`, '_blank');
                    setTimeout(n.close.bind(n), 10000);
                }
            }
        },
        colourMode () {
            this.updateColour();
        },
        selectedEnact () {
            this.updateColour();
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base.scss";
html,
body {
	height: 100%;
    width: 100%;
    @extend %standardFont;
}
.appFullscreenWrapper {
	height: calc(100% - 40px);
    margin-left: 40px;
    width: calc(100% - 40px);
	@include responsive('smUp') {
		overflow-y: hidden !important;
	}
    @include responsive('md') {
        margin-left: -10px;
        width: calc(100% + 10px);
    }
    &.hiddenOnDesktop {
        margin-left: -10px;
        width: calc(100% + 10px);
    }
	overflow-x: hidden !important;
}
#app {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	@extend %standardFont;
	height: 100%;

    background-image: linear-gradient(315deg, var(--darkGrad1) 0%, var(--darkGrad2) 74%);

    // &.niceScroll {
    //     @include responsive("sm") {
    //         overflow-y: auto;
    //     }
    // }

    &.theCurrent {
        background-image: url(./assets/imgs/blurred-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        @include responsive('md') {
            background-size: 100%;
        }
    }
    &.freqOut {
        background-image: url(./assets/imgs/blurred-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        @include responsive('md') {
            background-size: 100%;
        }
    }
    // color: white;

    transition: background-image 0.2s ease-in-out;

	#main {
        // margin-left: 210px;
        flex-grow: 1;
        padding: 10px 10px 10px 20px;
        height: 100%;

        // @include responsive('smUp') {
            overflow-y: hidden !important;
        // }
        // @include responsive("md") {
        //     overflow-y: scroll !important;
        // }
        overflow-x: hidden;
	}
	.niceScroll {
		overflow-y: auto;
        @include responsive("sm") {
            overflow-y: overlay;
        }
	}
	.niceScroll::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: #f5f5f5;
        @include responsive("sm") {
            width: 5px;
            height: 5px;
        }
	}

	.niceScroll::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
		background-color: $ALTERNATIVE1;
		border-radius: 8px;
	}
	.niceScroll::-webkit-scrollbar-track {
		box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
		background-color: #f5f5f5;
		border-radius: 8px;
	}
}
</style>
