var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('absoluteModal',{staticClass:"filterWrapper",attrs:{"showShadow":"","title":"Table Filters"},on:{"close":_vm.onClickOutside}},[_c(_vm.internalFooterTeleport ? 'portal' : 'div',{tag:"component",attrs:{"to":"tableOptions"}},[_c('draggable',{key:_vm.resetNo,staticClass:"checkWrapper niceScroll",on:{"end":_vm.headerOrderChange},model:{value:(_vm.headersToOrder),callback:function ($$v) {_vm.headersToOrder=$$v},expression:"headersToOrder"}},_vm._l((_vm.headersToOrder),function(header){return _c('div',{key:header.id,staticClass:"check card",class:_vm.internalFooterTeleport ? 'darker' : 'midder'},[(!_vm.disableReorder)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click and drag to reorder'),expression:"'Click and drag to reorder'"}],staticClass:"icofont-drag"}):_vm._e(),_c('p-check',{staticClass:"p-icon p-square p-fill p-smooth",attrs:{"name":"check","color":"primary"},on:{"change":function($event){return _vm.selectHeader(header.id)}},model:{value:(_vm.columnsToShow[header.id]),callback:function ($$v) {_vm.$set(_vm.columnsToShow, header.id, $$v)},expression:"columnsToShow[header.id]"}},[_c('i',{staticClass:"icon icofont-check",attrs:{"slot":"extra"},slot:"extra"}),_vm._v(" "+_vm._s(header.title)+" ")])],1)}),0),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('h3',[_vm._v("Table Units:")]),_c('buttonHolder',{attrs:{"smaller":"","initialSelectedId":_vm.tableUnit,"options":[{
          id: 'inherit', name: 'Inherit', info: 'Table will default to the site-wide setting (see Settings panel)',
        }, {
          id: 'cell', name: 'In Cell',
        }, {
          id: 'header', name: 'In Header',
        }, {
          id: 'hidden', name: 'Hidden',
        }]},on:{"select":function (v) { return _vm.$emit('tableUnitValue', v.id); }}})],1),_c('h2',[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("Note:")]),_vm._v(" To filter a header, please select it on the table.")]),_c('div',{staticClass:"flex"},[_c('div',[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove all filters from each header on the table'),expression:"'Remove all filters from each header on the table'"}],staticClass:"button smaller",on:{"click":_vm.resetHeaders}},[_vm._v("Reset Headers")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Reset order and hidden headers'),expression:"'Reset order and hidden headers'"}],staticClass:"button smaller",on:{"click":_vm.reset}},[_vm._v("Reset Table")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }